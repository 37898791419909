.home-widget-1 {
  &__list {
    @include no-scrollbar();

    @include media-breakpoint-down(xs) {
      overflow-x: scroll;
      flex-wrap: nowrap;
    }

    &-element {
      @include media-breakpoint-down(xs) {
        flex: 0 0 ((100 / 7) * 3%);
        max-width: ((100 / 7) * 3%);
      }

      .btn {
        padding: 0;
        font-size: rem-calc(22);
        line-height: rem-calc(32);
        transition: padding $transition-duration $transition-timing;

        &-outline-primary {
          @include hover-focus-active {
            color: $primary !important;
            background-color: transparent !important;
            box-shadow: none !important;
            border-color: transparent !important;
          }
        }

        &-editorial {
          min-width: auto;

          &::before {
            background-color: transparent;
          }

          &--no-border {
            border-color: transparent;
          }
        }

        .h3 {
          @include media-breakpoint-down(xs) {
            font-size: rem-calc(22);
            line-height: rem-calc(32);
          }
        }

        &-icon {
          right: initial;
          left: 0;
          opacity: 0;
        }

        @include hover-focus-active {
          color: $primary;
          text-decoration: none;

          @include media-breakpoint-up(sm) {
            padding-left: $spacer * 1.7;
          }

          .btn-icon {
            opacity: 1;
          }
        }
      }
    }
  }

  &__tile-element {
    &:last-child {
      @include media-breakpoint-down(md) {
        display: none;
      }
    }

    transform: translateX(5%);
    opacity: 0;
    animation: slideFadeIn 500ms $transition-timing forwards;
  }

  &__tile {
    &.leaving {
      .home-widget-1__tile-element {
        animation: slideFadeOut 500ms $transition-timing forwards;
      }
    }
  }

  .product {
    &--grid-tile {
      @include media-breakpoint-up(sm) {
        @include regular(xs);
      }

      // @include media-breakpoint-up(md) {
      //   @include tiny(md);
      // }
    }
  }

  // Page Designer
  &__tile-wrapper {
    @include media-breakpoint-up(sm) {
      display: none;

      &:first-child {
        display: block;
      }
    }
  }
}

@keyframes slideFadeIn {
  from {
    transform: translateX(5%);
    opacity: 0;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideFadeOut {
  from {
    transform: translateX(0);
    opacity: 1;
  }

  to {
    transform: translateX(5%);
    opacity: 0;
  }
}
