.hub-carousel {
  .folder-name {
    font-size: rem-calc(24);

    @include media-breakpoint-up(lg) {
      font-size: rem-calc(50);
    }
  }

  .vertical-folder {
    line-height: 1;
    font-size: rem-calc(10);

    @include media-breakpoint-up(lg) {
      font-size: rem-calc(18);
    }
  }

  &-tiles {
    &__main {
      &-img {
        aspect-ratio: 4/5;
        object-fit: cover;
      }

      &-text {
        font-size: rem-calc(16);
        font-family: $headings-font-family;

        @include media-breakpoint-up(lg) {
          font-size: rem-calc(21);
        }
      }
    }
  }

  &__grid {
    padding-right: rem-calc(112);

    @include media-breakpoint-down(md) {
      padding-right: rem-calc(135);
    }
  }

  &__indicators {
    height: 3px;

    .indicator {
      cursor: pointer;
      background-color: $gray-500;

      &:focus-visible,
      &:focus {
        outline: none;
        box-shadow: none;
      }
    }

    .indicator.active {
      background-color: $black;
    }
  }

  .draggable-horizontal-slider {
    width: 100%;

    &__content {
      > div {
        &:first-child {
          @include scrollable();
        }
      }
    }

    &__control {
      z-index: -1;
      position: absolute;
      width: 10%;
      height: 100%;
      top: 0;

      &:hover {
        cursor: move;
      }

      &--left {
        left: 0;
      }

      &--right {
        right: 0;
      }
    }

    .disable-pointer-events {
      pointer-events: none;
    }
  }
}
