@import "../mixins";

.title-and-cta {
  &__title {
    font-size: rem-calc(28);
    line-height: rem-calc(35);
    font-weight: $font-weight-semibold;

    @include media-breakpoint-up(lg) {
      font-size: rem-calc(40);
      line-height: rem-calc(71);
    }
  }

  &__cta {
    @include widget_cta;
  }
}
