@import "../mixins";

.square-block__content:has(.textSquare) {
  padding-inline: 0;

  .textSquare {
    &__title {
      @include widget_light_title;

      font-size: rem-calc(130);
      line-height: 1.05;

      @include media-breakpoint-down(md) {
        font-size: rem-calc(50);
      }
    }

    &__copy {
      @include widget_body;
    }

    &__cta {
      @include widget_cta;
    }
  }
}
