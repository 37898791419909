@import "../../mixins";

html:has(.shop-the-look .modal.show),
body:has(.shop-the-look .modal.show) {
  overflow: hidden;
}

.shop-the-look {
  &__inner .position-relative {
    @include media-breakpoint-up(lg) {
      position: relative;
      overflow: hidden;
      height: 0;
      padding-top: (9/16) * 100%;

      figure {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        object-fit: cover;
      }
    }
  }

  .text {
    @include media-breakpoint-up(lg) {
      position: absolute;
      top: 0;
      object-fit: cover;
      width: 100%;
      height: 100%;
      margin-left: rem-calc(55);
      margin-bottom: rem-calc(68);
      padding-bottom: rem-calc(70) !important;
    }
  }

  .title,
  .desc,
  .cta {
    @include media-breakpoint-down(md) {
      color: $black !important;
      border-color: $black !important;
    }
  }

  .title {
    font-family: $headings-font-family;
    font-weight: $headings-font-weight;

    &.small-text {
      font-size: rem-calc(35);
      line-height: 0.9;

      @include media-breakpoint-up(md) {
        font-size: rem-calc(130);
      }

      @include media-breakpoint-up(lg) {
        font-size: rem-calc(180);
      }

      @include media-breakpoint-up(xl) {
        font-size: rem-calc(200);
      }
    }

    &.medium-text {
      font-size: rem-calc(45);
      line-height: 0.9;

      @include media-breakpoint-up(md) {
        font-size: rem-calc(150);
      }

      @include media-breakpoint-up(lg) {
        font-size: rem-calc(200);
      }

      @include media-breakpoint-up(xl) {
        font-size: rem-calc(230);
      }
    }

    &.large-text {
      font-size: rem-calc(55);
      line-height: 0.9;

      @include media-breakpoint-up(md) {
        font-size: rem-calc(170);
      }

      @include media-breakpoint-up(lg) {
        font-size: rem-calc(220);
      }

      @include media-breakpoint-up(xl) {
        font-size: rem-calc(250);
      }
    }
  }

  .desc {
    font-family: $font-family-sans-serif;

    &.small-text {
      font-size: rem-calc(10);

      @include media-breakpoint-up(md) {
        font-size: rem-calc(14);
        margin-bottom: rem-calc(22);
      }
    }

    &.medium-text {
      font-size: rem-calc(12);
      line-height: rem-calc(20);

      @include media-breakpoint-up(md) {
        font-size: rem-calc(16);
        line-height: rem-calc(20);
        margin-bottom: rem-calc(22);
      }
    }

    &.large-text {
      font-size: rem-calc(14);
      line-height: rem-calc(20);

      @include media-breakpoint-up(md) {
        font-size: rem-calc(18);
        line-height: 1.2;
        margin-bottom: rem-calc(22);
      }
    }
  }

  .cta {
    @include widget_cta;

    &.small-text {
      font-size: rem-calc(13);
      line-height: rem-calc(18);
    }

    &.medium-text {
      font-size: rem-calc(16);
      line-height: rem-calc(21);
    }

    &.large-text {
      font-size: rem-calc(18);
      line-height: rem-calc(23);
    }
  }
}

.stl-modal {
  .modal-dialog {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) !important;
    width: 70%;

    @include media-breakpoint-up(lg) {
      width: 17.5rem;
    }
  }

  &.show {
    .modal-dialog {
      transform: translate(-50%, -50%) !important;
    }
  }

  .modal-content {
    align-items: center;

    .product-card {
      .image-badge {
        display: none;
      }
    }
  }

  .modal-header {
    border: 0;
    margin-top: rem-calc(-10);
    padding: 0;
    position: absolute;
    z-index: 10;

    .close {
      background: $white;
      border: 1px solid $black;
      border-radius: 50%;
      padding: 0;
      height: rem-calc(22);
      width: rem-calc(22);
      position: relative;

      .isicon--close {
        position: absolute;
        top: 40%;
        left: 50%;
        transform: translate(-50%, -40%);
      }
    }
  }

  .link {
    font-family: $headings-font-family;
    font-size: rem-calc(16);
    font-weight: $font-weight-semibold;
    line-height: rem-calc(21);
    margin-bottom: rem-calc(5);
    margin-top: rem-calc(10);
    height: auto !important;

    @include media-breakpoint-up(md) {
      font-size: rem-calc(18);
      line-height: 1.2;
    }
  }

  .add-to-cart {
    border-bottom: 2px solid $black;
    font-family: $headings-font-family;
    font-size: rem-calc(13);
    font-weight: $font-weight-bold;
    line-height: rem-calc(21);

    &:hover {
      text-decoration: none;
    }
  }
}
