.hotspot {
  z-index: 2;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 50%;
  cursor: pointer;
  height: rem-calc(18);
  margin-top: rem-calc(-9);
  margin-left: rem-calc(-9);
  position: absolute;
  width: rem-calc(18);

  // @include media-breakpoint-up(md) {
  //   height: rem-calc(26);
  //   margin-top: rem-calc(-13);
  //   margin-left: rem-calc(-13);
  //   width: rem-calc(26);
  // }

  // @include media-breakpoint-up(xl) {
  //   height: rem-calc(50);
  //   margin-top: rem-calc(-25);
  //   margin-left: rem-calc(-25);
  //   width: rem-calc(50);
  // }

  &::before {
    border: 1px solid $white;
    border-radius: 50%;
    content: " ";
    display: block;
    height: rem-calc(32);
    margin-left: rem-calc(-7);
    margin-top: rem-calc(-7);
    width: rem-calc(32);

    // @include media-breakpoint-up(md) {
    //   border-width: 2px;
    //   height: rem-calc(40);
    //   margin-left: rem-calc(-7);
    //   margin-top: rem-calc(-7);
    //   width: rem-calc(40);
    // }

    // @include media-breakpoint-up(xl) {
    //   border-width: 2px;
    //   height: rem-calc(74);
    //   margin-left: rem-calc(-12);
    //   margin-top: rem-calc(-12);
    //   width: rem-calc(74);
    // }
  }

  &-plus {
    &::before,
    &::after {
      content: "";
      position: absolute;
      background: $white;
    }

    &::after {
      top: 50%;
      left: 50%;
      height: rem-calc(1);
      width: rem-calc(8);
      transform: translate(-50%, -50%);

      // @include media-breakpoint-up(md) {
      //   height: rem-calc(1.3);
      //   width: rem-calc(16);
      // }

      // @include media-breakpoint-up(xl) {
      //   height: rem-calc(2);
      // }
    }

    &::before {
      left: 50%;
      top: 50%;
      width: rem-calc(1);
      height: rem-calc(8);
      transform: translate(-50%, -50%);

      // @include media-breakpoint-up(md) {
      //   width: rem-calc(1.3);
      //   height: rem-calc(16);
      // }

      // @include media-breakpoint-up(xl) {
      //   width: rem-calc(2);
      // }
    }
  }
}
