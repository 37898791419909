.home-widget-hero {
  position: relative;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background-color: $gray-100;
  height: 0;
  padding-top: (14/9) * 100%; // 9:14 aspect ratio

  @include media-breakpoint-up(sm) {
    padding-top: (11/16) * 100%; // 16:11 aspect ratio
  }

  @include media-breakpoint-up(lg) {
    padding-top: (9/16) * 100%; // 16:9 aspect ratio
  }

  @include media-breakpoint-up(xl) {
    padding-top: (9/21) * 100%; // 21:9 aspect ratio
  }

  @include videoVolumeButton;

  img,
  video {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    object-fit: cover;
    width: 100%;
    height: 100%;
    font-family: 'object-fit: cover;'; // polyfill
  }

  .container {
    z-index: 1;
  }

  &__title {
    padding: $spacer * 0.75 $spacer * 0.5;

    @include media-breakpoint-up(md) {
      padding: rem-calc(17.5) $spacer;
    }
  }

  &__element {
    .icon {
      line-height: rem-calc(30);

      @include media-breakpoint-up(lg) {
        line-height: rem-calc(35);
      }

      .isicon {
        @include media-breakpoint-down(md) {
          width: rem-calc(22) !important;
          height: rem-calc(22) !important;
          line-height: rem-calc(22) !important;
        }
      }
    }

    &-button {
      .btn {
        min-width: 100%;
        border-right: 1px solid $gray-200 !important;

        @include media-breakpoint-down(sm) {
          padding: rem-calc(17) 0 !important;
        }
      }

      &.col-sm-6 {
        &:last-child {
          @include media-breakpoint-down(lg) {
            padding-right: 0 !important;
          }
        }

        &:not(:last-child) {
          @include media-breakpoint-down(lg) {
            padding-left: 0 !important;
          }

          .btn {
            @include media-breakpoint-down(md) {
              border-right: 0 !important;
            }
          }
        }

        .btn {
          @include media-breakpoint-down(md) {
            border-top: 1px solid $gray-200 !important;
          }

          span {
            @include media-breakpoint-down(xs) {
              display: block;
              width: 100%;
              text-align: center;
            }
          }
        }
      }

      &:not(.col-sm-6) {
        .btn {
          @include media-breakpoint-down(xs) {
            border-left: 0 !important;
            border-top: 1px solid $gray-200 !important;
          }
        }
      }
    }
  }
}
