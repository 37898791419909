@import "~common/utils/mixins";

$body-color: $black;

// Fonts
$headings-font-family: "Jost", "Helvetica Neue", Helvetica, Arial, sans-serif;
$font-family-sans-serif: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;

$font-weight-semibold: 500;
$font-weight-bold: 600;
$font-weight-bolder: 700;
$font-weight-lighter: 200;

// Text size
$headings-font-weight: 300;

// H1 MAPS
$font-h1-maps: (
  xs: (
    "font-size": 27,
    "line-height": 37
  ),
  md: (
    "font-size": 34,
    "line-height": 43
  )
);

$font-h1-map: map-get($font-h1-maps, xs);

// H2 MAPS
$font-h2-maps: (
  xs: (
    "font-size": 22,
    "line-height": 33
  ),
  md: (
    "font-size": 30,
    "line-height": 40
  )
);

$font-h2-map: map-get($font-h2-maps, xs);

//H3 MAPS
$font-h3-maps: (
  xs: (
    "font-size": 18,
    "line-height": 28
  ),
  md: (
    "font-size": 22,
    "line-height": 32
  )
);

$font-h3-map: map-get($font-h3-maps, xs);
