.loyalty-levels-banner {
  padding-top: rem-calc(48);
  padding-bottom: rem-calc(32);

  &__icons {
    display: none;
  }

  &__title {
    font-size: rem-calc(30);
    font-weight: 400;
    font-family: "Jost", "Helvetica Neue", Helvetica, Arial, sans-serif;
    margin-top: rem-calc(24);
    margin-bottom: rem-calc(24);

    @include media-breakpoint-up(md) {
      font-size: rem-calc(40);
    }
  }

  &__navigation {
    display: none;

    @include media-breakpoint-up(md) {
      display: flex;
      list-style: none;
      position: relative;
      column-gap: rem-calc(24);
      padding: rem-calc(24) 0;
      padding-bottom: 0;
      font-family: "Jost", "Helvetica Neue", Helvetica, Arial, sans-serif;
      font-size: rem-calc(24);

      li a {
        position: relative;
        color: $black;

        &.active,
        &:hover {
          font-weight: 500;
          text-decoration: none !important;
        }

        &.active::after {
          content: "";
          position: absolute;
          bottom: rem-calc(-1);
          left: rem-calc(0);
          width: 100%;
          border-bottom: rem-calc(2.5) solid var(--loyalty-border-color, $black);
          z-index: 1;
        }
      }
    }
  }

  &__navigation::after {
    content: "";
    position: absolute;
    bottom: rem-calc(0);
    width: 100%;
    border-bottom: rem-calc(2.5) solid $gray-200;
  }

  &__section {
    .mobile-menu {
      display: flex;
      border-bottom: rem-calc(1) solid $gray-200;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      padding: 0;
      position: relative;

      &:has(p.color-underline) {
        border-bottom: none;
      }

      & p {
        margin-top: rem-calc(14);
        margin-bottom: rem-calc(14);
        position: relative;
        font-size: rem-calc(18);
        font-family: "Jost", "Helvetica Neue", Helvetica, Arial, sans-serif;
      }

      & p.color-underline span::after {
        content: "";
        position: absolute;
        bottom: rem-calc(0);
        left: rem-calc(0);
        width: 130%;
        border-bottom: rem-calc(2) solid var(--loyalty-border-color, $black);
      }

      .icon {
        width: rem-calc(20);
        height: rem-calc(20);
        transform: rotate(180deg);
      }

      &.no-rotate {
        .icon {
          transform: rotate(0deg) !important;
        }
      }

      @include media-breakpoint-up(md) {
        display: none;
      }
    }
  }

  &__section &__component {
    display: none;
  }

  @include media-breakpoint-up(md) {
    &__section &__component {
      display: flex;
    }

    &__section:not(:first-child) &__component {
      display: none;
    }
  }

  &__component {
    display: flex;
    flex-wrap: wrap;
    transition: opacity 0.1s ease-in;
    margin-top: rem-calc(6);
    opacity: 1;

    @include media-breakpoint-up(md) {
      margin-top: rem-calc(24);
    }

    &__image {
      display: none;
      height: auto;

      @include media-breakpoint-up(md) {
        display: block;
        position: relative;
        width: 25%;
        min-height: rem-calc(380);

        img {
          width: 100%;
          height: 100%;
          position: absolute;
          object-fit: cover;
        }
      }
    }

    &__benefits {
      width: 100%;
      height: auto;
      padding: rem-calc(10);
      padding-left: rem-calc(0);

      @include media-breakpoint-up(md) {
        padding-left: rem-calc(20);
        width: 75%;
      }

      .card-description {
        @include media-breakpoint-up(md) {
          margin-top: rem-calc(34);
          margin-bottom: rem-calc(45);
        }
      }

      .nqp-title {
        font-family: "Jost", "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-weight: 500;
        font-size: rem-calc(16);
      }
    }

    .experience-benefits {
      display: flex;
      flex-wrap: wrap;

      .icon {
        width: rem-calc(20);
        height: rem-calc(20);
        margin-bottom: rem-calc(4);

        @include media-breakpoint-up(md) {
          margin-bottom: rem-calc(0);
        }
      }

      .experience-assets-loyalty_level_benefit {
        width: 100%;
        padding: 10px;
        box-sizing: border-box;
        padding-left: 0px;

        @include media-breakpoint-up(md) {
          padding-left: 10px;
        }

        &:not(:last-child) {
          border-bottom: 1px solid $gray-200;

          @include media-breakpoint-up(md) {
            border-bottom: none;
          }
        }

        @include media-breakpoint-up(md) {
          width: 33%;
        }

        .benefit-title {
          font-size: rem-calc(14);
          font-weight: 500;
          display: inline;
          font-family: "Jost", "Helvetica Neue", Helvetica, Arial, sans-serif;

          @include media-breakpoint-up(md) {
            font-size: rem-calc(13);
          }
        }

        .benefit-description {
          margin-top: rem-calc(14);
          font-size: rem-calc(13);
          line-height: 1.375;

          @include media-breakpoint-up(md) {
            font-size: rem-calc(12);
          }
        }
      }
    }
  }

  @include media-breakpoint-up(md) {
    padding-top: rem-calc(56);
    padding-bottom: rem-calc(20);

    &__navigation {
      column-gap: rem-calc(24);
    }
  }
}
