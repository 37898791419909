// Home Widget 2 Style
.home-widget-2-5 {
  &__image-wrapper {
    overflow: hidden;
  }

  &__title-link {
    &:hover {
      text-decoration: none !important;
    }
  }
}
