// Override AOS
[data-aos^="zoom"][data-aos^="zoom"] {
  opacity: 1;
}

[data-aos="zoom-out"] {
  transform: scale(1.15);
}

// Custom AOS animations
$aos-distance-small: rem-calc(14);

@mixin aos-disable {
  transition: none !important;
  transform: translate3d(0, 0, 0);
  opacity: 1;
}

[data-aos-medium] {
  @include media-breakpoint-down(sm) {
    @include aos-disable();
  }
}

[data-aos-large] {
  @include media-breakpoint-down(md) {
    @include aos-disable();
  }
}

[data-aos="clip-right"] {
  clip-path: inset(0px 100% 0px 0px);
  transition-property: clip-path;

  &.aos-animate {
    clip-path: inset(0px 0% 0px 0px);
  }
}

[data-aos="fade-left-small"] {
  transform: translate3d($aos-distance-small, 0, 0);
}

[data-aos="fade-up-left-small"] {
  transform: translate3d($aos-distance-small, $aos-distance-small, 0);
}

[data-aos="fade-up-small"] {
  transform: translate3d(0, $aos-distance-small, 0);
}

[data-aos="slide-up-small"] {
  transform: translate3d(0, 25%, 0);
}

[data-aos="swing-down"] {
  transform: translate3d(0, -50%, 0);

  &.aos-animate {
    animation: AOSSwingDown 1.5s $transition-timing;
  }
}

@keyframes AOSSwingDown {
  0% {
    transform: translate3d(0, -50%, 0);
  }

  50% {
    transform: translate3d(0, calc(-50% + #{$aos-distance-small / 1.5}), 0);
  }

  100% {
    transform: translate3d(0, -50%, 0);
  }
}

.parallax-mirror,
.parallax-slider {
  will-change: transform;
}
