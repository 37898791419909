// Easings implementations
// Default timing function: 'ease'

[data-aos] {
  @each $key, $val in $easing {
    body[data-aos-easing="#{$key}"] &,
    &[data-aos][data-aos-easing="#{$key}"] {
      transition-timing-function: $val;
    }
  }
}
