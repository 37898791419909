$carousel-image-transition-timing: ease;
$carousel-image-transition-duration: 1s;
$carousel-image-opacity-transition-duration: 460ms;
$carousel-body-transition-duration: 560ms;
$carousel-body-transition-delay: 480ms;
$carousel-body-second-delay: 1s;

.home-widget-3 {
  overflow: hidden;

  &__title {
    &-text {
      overflow-x: scroll;
      white-space: nowrap;

      @include no-scrollbar();

      .dot {
        position: relative;
        padding-right: $spacer;
        padding-left: $spacer;

        &::after {
          display: block;
          content: '';
          position: absolute;
          top: 50%;
          left: calc(50% - 3px);
          width: rem-calc(6);
          height: rem-calc(6);
          background-color: $white;

          @include border-radius(50%);
        }
      }
    }
  }

  &__tiles {
    justify-content: center;
    align-content: flex-start;
    align-items: flex-start;

    @include media-breakpoint-down(md) {
      overflow-x: hidden;
      flex-wrap: nowrap;
    }

    &-element {
      width: 72%; // 71.7%
      flex-basis: auto;
      flex-grow: 0;

      &:nth-child(n+4) {
        display: none;
      }

      @include media-breakpoint-up(sm) {
        width: 40%; // 39.66667%
      }

      @include media-breakpoint-up(lg) {
        width: 1/3 * 100%;
      }

      .item {
        position: relative;
        background-color: $white;
        overflow: hidden;
      }

      .product {
        position: relative;
        z-index: 1;
        width: 100%;

        &-tile {
          margin-bottom: 0 !important;
        }

        .image-container {
          transform: translateX(0);
          will-change: transform, opacity;
        }
      }

      &:nth-child(1) {
        @include media-breakpoint-down(md) {
          margin-left: -100% !important;
        }
      }

      &:nth-child(3) {
        @include media-breakpoint-down(md) {
          margin-right: -100% !important;
        }
      }

      .color-swatches {
        .swatches__label {
          &.empty {
            display: block;
          }
        }
      }
    }

    &[data-direction="right"] {
      .home-widget-3__tiles-element {
        .item {
          &.animate {
            .product {
              .image-container {
                transition: transform $carousel-image-transition-duration $carousel-image-transition-timing 100ms, opacity $carousel-image-opacity-transition-duration ease;
                transform: translateX(-25%);
                opacity: 0;
              }

              .tile-body {
                opacity: 0;
                transition: opacity $carousel-body-transition-duration $transition-timing $carousel-body-transition-delay;
              }

              &:last-child {
                &:not(:first-child) {
                  max-width: 100%;

                  .image-container {
                    transition: transform $carousel-image-transition-duration $carousel-image-transition-timing 100ms;
                    transform: translateX(0);
                    opacity: 1;
                  }

                  .tile-body {
                    opacity: 1;
                    transition-delay: $carousel-body-second-delay;
                  }
                }
              }
            }
          }
        }

        .product {
          transform: translateX(0);
          z-index: 0;

          &:last-child {
            &:not(:first-child) {
              position: absolute;
              top: 0;
              left: 0;
              z-index: 1;

              .image-container {
                transform: translateX(100%);
              }

              .tile-body {
                opacity: 0;
              }
            }
          }
        }
      }
    }

    &[data-direction="left"] {
      .home-widget-3__tiles-element {
        .item {
          &.animate {
            .product {
              .image-container {
                transition: transform $carousel-image-transition-duration $carousel-image-transition-timing 100ms, opacity $carousel-image-opacity-transition-duration ease;
                transform: translateX(25%);
                opacity: 0;
              }

              .tile-body {
                opacity: 0;
                transition: opacity $carousel-body-transition-duration $transition-timing $carousel-body-transition-delay;
              }

              &:first-child {
                &:not(:last-child) {
                  max-width: 100%;

                  .image-container {
                    transition: transform $carousel-image-transition-duration $carousel-image-transition-timing 100ms;
                    transform: translateX(0);
                    opacity: 1;
                  }

                  .tile-body {
                    opacity: 1;
                    transition-delay: $carousel-body-second-delay;
                  }
                }
              }
            }
          }
        }

        .product {
          transform: translateX(0);
          z-index: 0;

          &:first-child {
            &:not(:last-child) {
              position: absolute;
              top: 0;
              left: 0;
              z-index: 1;

              .image-container {
                transform: translateX(-100%);
              }

              .tile-body {
                opacity: 0;
              }
            }
          }
        }
      }
    }
  }

  &__controls {
    // on desktop, hide them for < 3 items
    @include media-breakpoint-up(md) {
      [data-tiles="1"] + &,
      [data-tiles="2"] + &,
      [data-tiles="3"] + & {
        display: none;
      }
    }

    .btn {
      width: rem-calc(80);
      height: rem-calc(80);
      min-width: rem-calc(80);
      position: absolute;
      top: calc(50% - #{rem-calc(40)});
      left: 0;

      &[data-action="next"] {
        left: auto;
        right: 0;
      }

      &-icon {
        right: 50%;
        left: auto;
        margin-right: -10px;
      }

      @include hover {
        color: white;
      }
    }
  }

  .product {
    &--grid-tile {
      @include tiny(xs);

      @include media-breakpoint-up(sm) {
        @include big();
      }
    }
  }

  .container-fluid {
    @include media-breakpoint-down(md) {
      padding: 0;
      overflow: hidden;
    }

    // make normal container for desktop and up
    @include media-breakpoint-up(lg) {
      @include make-container-max-widths();
    }
  }
}
