.loyalty-banner {
  position: relative;
  height: 450px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: $white;

  &__title {
    line-height: 1;
    font-size: 5rem;

    @include media-breakpoint-up(md) {
      font-size: 10rem;
    }
  }

  &__cta {
    color: $white;
    border-bottom: 1px solid $white;
  }

  &__image {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;

    img {
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
}

.vip-sale-banner {
  background-color: #f9e1e9;
  padding: 3rem 1.5rem;

  &__title {
    font-size: 2.55rem;
    font-weight: 500;
  }

  &__cta {
    border: 1px solid $black;
    width: 100%;
    margin-bottom: 0;
  }
}

.experience-layouts-loyalty_modal {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 9999;

  .overlay-modal-loyalty {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: black;
    opacity: 0.5;
  }
}

.loyaltyModal {
  position: relative;

  .modal-dialog {
    width: 95%;
    max-width: 850px;
  }

  .modal-body {
    padding: 0;
  }

  .close {
    position: absolute;
    top: -5px;
    right: -5px;
    padding: 0 1rem;
    font-size: 2.5rem;
    z-index: 1;
    color: $white;
  }

  &__title {
    line-height: 1;
    font-size: 4.5rem;

    @include media-breakpoint-up(md) {
      font-size: 5.72rem;
    }
  }

  &__description {
    font-size: 14px;
    line-height: 20px;
  }

  &__text {
    padding: 2rem;
  }

  &__image {
    img {
      height: 100%;
      object-fit: cover;
    }
  }

  &__cta {
    margin-top: 3rem;
    border: 1px solid $black;
    width: 150px;
    padding: 0.5rem 1rem;
    text-align: center;
    text-transform: capitalize;
  }
}

.loyalty-hero {
  height: 700px;
  display: flex;
  align-items: flex-end;
  color: $white;

  &__title {
    font-size: 5.5rem;
    font-size: 2.8rem;
    line-height: 1.1;

    @include media-breakpoint-up(md) {
      font-size: 5.5rem;
    }
  }

  &__image {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top;
    }
  }

  &__cta {
    color: $white;
    border: 1px solid $white;
  }
}

.loyalty-normal-text {
  padding: 4rem 0;

  &__title {
    font-weight: 500;
  }

  &__description {
    font-size: 14px;
  }
}

.loyalty-three-col {
  &__title {
    font-weight: 500;
  }

  &__description {
    @include media-breakpoint-up(md) {
      min-height: 80px;
    }
  }

  &__cta {
    text-decoration: underline;
  }
}

.loyalty-nav-tabs {
  .nav-pills {
    .nav-item {
      .nav-link {
        padding: 0.1rem 3rem 0.1rem 0;
        font-size: 1.3rem;
        text-decoration: underline !important;

        @include media-breakpoint-up(md) {
          padding: 0.1rem 1rem 0.1rem 0;
          font-size: 1rem;
        }
      }
    }
  }

  &__image {
    img {
      object-fit: cover;
    }
  }

  &__floor-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #000;
    padding: 0 3rem;
    color: $white;
  }

  &__title {
    font-size: 6rem;
    margin-bottom: 3rem;
    line-height: 6rem;

    @include media-breakpoint-down(lg) {
      line-height: 6.6rem;
    }
  }
}

.loyalty-benefits-table {
  .experience-component {
    margin: 0 !important;
  }

  td {
    border: none;
    border-right: 1px solid #000;
  }

  th {
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    padding-bottom: 1rem;
    text-decoration: underline;

    @include media-breakpoint-down(sm) {
      font-size: 13px;
      padding: 1rem 0px;
      transform: rotate(-90deg);
      white-space: nowrap;
    }
  }

  .custom-column {
    position: relative;
    z-index: 2;

    @include media-breakpoint-up(md) {
      max-width: 40px;
    }
  }

  .vertical-element {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    color: $white;

    @include media-breakpoint-up(md) {
      width: 40px;
    }

    .text {
      transform: rotate(-90deg);
      white-space: nowrap;
      font-size: 13px;
    }

    &.element-col-1 {
      height: 62%;
      background-color: #92918f;

      @include media-breakpoint-down(md) {
        height: 56%;
      }
    }

    &.element-col-2 {
      height: 38%;
      background-color: #3c3c3c;

      @include media-breakpoint-down(md) {
        height: 44%;
      }
    }
  }

  tr {
    td {
      padding: 0.5rem 0;

      &:first-child {
        @include media-breakpoint-down(sm) {
          width: 130px;
          display: block;

          p {
            font-size: 10px;
          }
        }
      }
    }
  }

  &__circle {
    width: 15px;
    height: 15px;
    margin: 0 auto;
    background-color: #000;
    border-radius: 50px;
  }
}
