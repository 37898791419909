@import "../mixins";

.textBlock {
  &__title {
    font-size: rem-calc(55);
    line-height: rem-calc(80);

    @include media-breakpoint-up(lg) {
      font-size: rem-calc(120);
      line-height: rem-calc(173);
    }
  }

  &__copy {
    @include widget_body;
  }

  &__cta {
    @include widget_cta;
  }

  justify-content: space-evenly;
}
