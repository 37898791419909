.squareSlider {
  .square-slide {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    cursor: pointer;

    img {
      z-index: 1;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }

    &__title {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      line-height: 0.9;

      @include media-breakpoint-up(lg) {
        position: relative;
        line-height: 1.1;
        top: 0;
        transform: translateY(0);
      }
    }

    &__bar {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      @include media-breakpoint-up(lg) {
        top: auto;
        bottom: 0;
        left: 5%;
        right: 5%;
        transform: translate(0, 0);
      }

      height: auto;
      z-index: 1;

      .btn-link {
        max-width: 140px;
        border: 2px solid $white;
        color: $white;
        border-color: $white;
        background-color: rgba(0, 0, 0, 0);

        &:hover {
          text-decoration: none;
        }

        @include media-breakpoint-up(lg) {
          max-width: unset;
        }

        &:focus,
        &:focus-visible {
          outline: none;
          box-shadow: none;
        }

        span {
          &:focus,
          &:focus-visible {
            outline: none;
            box-shadow: none;
          }
        }
      }
    }
  }

  .squareSlide.content-black .square-slide {
    &__title {
      color: $black;
    }

    .btn-link {
      color: $black;
      border-color: $black;
      background-color: rgba(0, 0, 0, 0);
    }
  }
}
