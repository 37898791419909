.home-widget-4 {
  height: rem-calc(475);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;

  @include media-breakpoint-up(lg) {
    height: rem-calc(500);
  }

  &__element {
    .icon {
      line-height: rem-calc(25);

      @include media-breakpoint-up(md) {
        line-height: rem-calc(35);
      }

      .isicon {
        @include media-breakpoint-down(md) {
          width: rem-calc(22) !important;
          height: rem-calc(22) !important;
          line-height: rem-calc(22) !important;
        }
      }
    }

    .btn {
      min-width: 100%;
      border-left: 1px solid $gray-200 !important;
      box-shadow: none;

      @include media-breakpoint-down(sm) {
        width: $spacer * 3;
      }

      &-icon {
        @include media-breakpoint-down(sm) {
          right: rem-calc(20);
        }
      }
    }
  }
}
