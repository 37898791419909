@import "../mixins";

.autoplay-carousel {
  z-index: 0;

  &.container-autoplay {
    @include media-breakpoint-up(lg) {
      max-width: 1600px;
    }
  }

  .hub-carousel__grid {
    padding-right: 0;
  }

  .autoplay-carousel-slide {
    flex-shrink: 0;
    padding-top: rem-calc(35);

    @include media-breakpoint-up(lg) {
      padding: 0;
    }

    .product-tile {
      position: relative;

      .price,
      .color-swatches {
        display: none !important;
      }

      .better-denim {
        display: none !important;
      }

      .tile-body {
        padding: rem-calc(10) rem-calc(10) rem-calc(10) 0 !important;
      }

      .wishlist-icon {
        @include media-breakpoint-up(lg) {
          right: rem-calc(15) !important;
        }

        @include media-breakpoint-down(md) {
          right: rem-calc(15) !important;
          top: rem-calc(15) !important;
        }
      }
    }

    .swiper-container {
      width: 100%;
    }

    .swiper-slide {
      text-align: center;
      width: auto;
    }
  }

  &__cta {
    @include widget_cta;
  }

  &__editorial-image {
    img {
      aspect-ratio: 4/5;
      object-fit: cover;
    }
  }
}
