@import "../mixins";

.shop-the-look {
  .title {
    font-size: rem-calc(130);
    line-height: 1.05;

    @include media-breakpoint-down(md) {
      font-size: rem-calc(50);
    }
  }

  .desc {
    @include widget_body;
  }

  &__inner .position-relative {
    @include media-breakpoint-up(lg) {
      padding-top: (9/18) * 100%;
    }
  }
}
