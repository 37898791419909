.image-carousel {
  .col.col--no-spacing {
    padding: 0px;
  }

  .hub-carousel__grid {
    padding-right: 0;
  }

  .image-carousel-slide {
    &__container {
      height: rem-calc(480);

      @include media-breakpoint-down(md) {
        width: rem-calc(231);
        height: rem-calc(307);
      }
    }

    &__img {
      aspect-ratio: 4/5;
      object-fit: cover;
    }

    &__text {
      position: absolute;
      left: 0;
      bottom: 0;
      margin-bottom: rem-calc(10);
      margin-left: rem-calc(25);
      font-size: rem-calc(19);
      font-family: $headings-font-family;
    }
  }

  &.large-set {
    .image-carousel-slide__container {
      width: rem-calc(231);

      @include media-breakpoint-up(lg) {
        width: rem-calc(380);
      }

      @media only screen and (min-width: 2200px) {
        width: rem-calc(500);
      }
    }
  }

  &:not(.large-set) .d-flex.flex-nowrap.row.row--no-spacing {
    @include media-breakpoint-up(lg) {
      justify-content: center;
    }

    .col.col--no-spacing {
      @include media-breakpoint-up(lg) {
        max-width: 20%;
        flex: 0 0 20%;
      }

      .image-carousel-slide {
        &__container {
          height: auto;

          @include media-breakpoint-down(md) {
            height: rem-calc(307);
          }
        }
      }
    }
  }
}
