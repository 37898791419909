@import "../../components/mixins";

.focus-category {
  @import "swiper/swiper-bundle";

  .row {
    @include media-breakpoint-down(md) {
      margin-left: -15px;
      margin-right: -15px;
    }
  }

  .experience-region.experience-focusProducts {
    display: flex;

    button {
      display: none;
    }

    .tile-body {
      display: none;
    }

    .experience-component {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 33.33333%;
      flex: 0 0 33.33333%;
      max-width: 33.33333%;
      padding-left: 7.5px;
      padding-right: 7.5px;

      picture {
        background-color: transparent;
      }
    }
  }

  &__media {
    position: relative;
    overflow: hidden;

    @include media-breakpoint-down(lg) {
      padding-left: 0;
      padding-right: 0;
    }

    figure {
      width: 100%;

      img {
        display: block;
        width: 100%;
        height: auto;
      }
    }

    .video {
      display: block;
      width: 100%;
      height: auto;
    }
  }

  &__content {
    @include media-breakpoint-down(md) {
      padding-left: 20px;
      padding-right: 20px;
    }

    .title {
      font-weight: $font-weight-semibold;
      font-size: rem-calc(70);
      line-height: rem-calc(101);

      @include media-breakpoint-down(md) {
        font-size: rem-calc(40);
        line-height: rem-calc(58);
      }
    }

    .description {
      @include widget_body;

      white-space: pre-wrap;
    }

    .cta {
      @include widget_cta;

      display: inline-block;
    }

    .card,
    .lazyload-container,
    .card-image-container .lazyload-container,
    .product-element .lazyload-container {
      background-color: transparent;
      border: none;
    }

    @include media-breakpoint-up(md) {
      .swiper-slide {
        margin-right: 0 !important;
      }
    }

    .swiper-pagination {
      &-bullet {
        width: rem-calc(50);
        height: rem-calc(3);
        background-color: #a7a7a7;
        border-radius: 0;
      }

      &-bullet-active {
        background-color: $white;
      }
    }
  }
}
