.hero-slider.magazine-slider {
  .product-tile {
    min-height: 450px;

    .image-container {
      position: relative;

      .wishlist-icon {
        position: absolute;
        top: 10px;
        right: 15px;
        z-index: 2;
      }
    }

    .pdp-link__anchor img {
      object-fit: cover;
      height: 100%;
    }
  }

  .tile-body {
    margin-bottom: 10px;

    .pdp-link__anchor.link {
      font-size: rem-calc(16);
      line-height: 1.1;
      font-weight: 600;
      overflow: visible;
      white-space: break-spaces;
    }

    .price {
      margin-top: 0;
    }
  }

  .carousel-indicators {
    position: absolute;
    bottom: 0px !important;
    z-index: 2;

    button {
      display: inline-block;
      width: 50px;
      height: 3px;
      background-color: gray;
      margin: 0 5px;
      cursor: pointer;
      border: none;
      padding: 0;

      &:focus,
      &:focus-visible {
        outline: none;
        box-shadow: none;
      }
    }

    button.active {
      background-color: black;
    }
  }

  .color-swatches {
    .swatches {
      a {
        pointer-events: none;

        .swatches__label {
          height: 10px;

          img {
            border-radius: 0;
            width: auto;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }
  }
}
