.category-carousel {
  position: relative;
  z-index: 0;

  .hub-carousel__grid {
    padding-right: 0;
  }

  .col.col--no-spacing {
    padding: 0px;
  }

  .product-carousel__tile {
    width: rem-calc(353);

    @include media-breakpoint-down(md) {
      width: 50vw;
      min-width: rem-calc(180);
    }

    .product-tile {
      .pdp-link__anchor {
        img {
          object-fit: cover;
          width: 100%;
          height: auto;
        }
      }

      .pdp-link .link,
      .price del,
      .price .value {
        white-space: unset;
        font-size: rem-calc(12);

        @include media-breakpoint-up(lg) {
          font-size: rem-calc(13);
        }
      }

      .wishlist-icon {
        position: absolute;
        top: 10px;
        right: 10px;
        z-index: 2;
      }

      .icon-betterdenim {
        &.isicon--better-denim {
          fill: $gray-400;
          bottom: 0;
          right: 0;
        }

        &_text {
          color: $gray-400;
        }
      }
    }
  }

  &__preview {
    .product-tile {
      .tile-body {
        display: none;
      }
    }
  }
}

.product-list-carousel {
  position: relative;
  z-index: 0;

  .hub-carousel__grid {
    padding-right: 0;
  }

  .col.col--no-spacing {
    padding: 0px;
    flex-grow: 0;
  }

  // .product-carousel__grid {
  //   width: fit-content;
  //   margin-inline: auto;
  // }

  .product--grid-tile {
    width: rem-calc(353);

    @include media-breakpoint-down(md) {
      width: 50vw;
      min-width: rem-calc(180);
    }

    .product-tile {
      .pdp-link__anchor {
        img {
          object-fit: cover;
          width: 100%;
          height: auto;
        }
      }

      .pdp-link .link,
      .price del,
      .price .value {
        white-space: unset;
        font-size: rem-calc(12);

        @include media-breakpoint-up(lg) {
          font-size: rem-calc(13);
        }
      }

      .wishlist-icon {
        position: absolute;
        top: 10px;
        right: 10px;
        z-index: 2;
      }

      .icon-betterdenim {
        &.isicon--better-denim {
          fill: $gray-400;
          bottom: 0;
          right: 0;
        }

        &_text {
          color: $gray-400;
          font-size: rem-calc(9);
        }
      }
    }
  }

  &__preview {
    .product-tile {
      .tile-body {
        display: none;
      }
    }
  }
}
