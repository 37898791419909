html:has(.editorial-popup-modal.show),
body:has(.editorial-popup-modal.show) {
  overflow-y: hidden;
}

.editorial-popup {
  position: relative;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background-color: $gray-100;
  height: 0;
  padding-top: (14/9) * 100%;

  @include media-breakpoint-up(sm) {
    padding-top: 125%;
  }

  @include media-breakpoint-up(md) {
    padding-top: (11/16) * 100%;
  }

  @include media-breakpoint-up(lg) {
    padding-top: 50%;
  }

  @include media-breakpoint-up(xl) {
    padding-top: (9/21) * 100%;
  }

  video {
    position: absolute;
    top: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

.editorial-popup-modal {
  &.show {
    @include media-breakpoint-up(md) {
      display: flex !important;
      align-items: center;
    }
  }

  .modal-dialog {
    margin: 0;
    max-width: 100%;

    @include media-breakpoint-up(md) {
      align-items: center;
      margin: rem-calc(25) auto;
      max-width: 66%;
      width: 66%;
    }

    .modal-content {
      border: 0px;

      @include media-breakpoint-up(md) {
        background: rgba(255, 255, 255, 0.9);
      }

      .product-card {
        .image-container {
          background-color: rgba(0, 0, 0, 0) !important;

          img {
            background-color: rgba(0, 0, 0, 0) !important;
          }
        }

        .image-badge {
          display: none;
        }
      }
    }

    .modal-header {
      border: 0px;
      padding: rem-calc(36) rem-calc(57);
      min-height: 50px;
    }

    .modal-fixed-body {
      @include media-breakpoint-up(md) {
        position: relative;
        overflow: hidden;
      }
    }

    .modal-fixed-body-inner {
      @include media-breakpoint-up(md) {
        overflow: scroll;

        &::-webkit-scrollbar {
          display: none; /* Hide scrollbar for Webkit (Chrome, Safari, Opera) */
        }
      }
    }

    .modal-body {
      padding: 0 rem-calc(8);

      @include media-breakpoint-up(md) {
        padding: 0 rem-calc(6);
        transition: transform 0.3s ease;
      }
    }

    .product {
      margin-bottom: rem-calc(24);

      @include media-breakpoint-up(md) {
        padding: rem-calc(12) 0;
        margin-bottom: 0;
      }

      &:nth-child(odd) {
        .product-card__image {
          margin-left: 0;
        }
      }

      &:nth-child(even) {
        .product-card__info {
          margin-left: 0;

          @include media-breakpoint-up(md) {
            order: 0;
          }
        }

        .product-card__image {
          @include media-breakpoint-up(md) {
            order: 1;
          }
        }
      }

      .product-card__info,
      .product-card__image {
        padding: 0 rem-calc(24);

        @include media-breakpoint-up(md) {
          padding: 0;
        }
      }

      .product-card__info {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      .product-card__image {
        margin-bottom: rem-calc(26);

        @include media-breakpoint-up(md) {
          margin-bottom: 0;
        }

        img {
          margin: 0 auto;
        }
      }
    }

    .tile-body {
      background: transparent;
    }

    .tile-name {
      align-items: center;
    }

    .card-title {
      font-family: $headings-font-family;
      font-size: rem-calc(45);
      font-weight: $font-weight-semibold;
      line-height: rem-calc(58);
    }

    .pdp-link {
      font-family: $headings-font-family;
      font-size: rem-calc(17);
      line-height: rem-calc(22);

      @include media-breakpoint-up(md) {
        font-size: rem-calc(45);
        line-height: rem-calc(58);
      }

      .link {
        line-height: 1.2;
        font-family: $headings-font-family;
        font-size: rem-calc(16);
        font-weight: $font-weight-semibold;
        height: auto !important;
        white-space: break-spaces !important;
        margin-bottom: 0;

        @include media-breakpoint-up(lg) {
          font-size: rem-calc(25);
        }
      }
    }

    .add-to-wishlist {
      border: 0;
      margin-bottom: 0;
      margin-left: auto;
      min-width: auto;
      padding: 0;
      top: auto;
      right: auto;
      position: relative;

      .isicon--wishlist {
        color: $black;
      }
    }

    .price {
      font-family: $headings-font-family;
      font-size: rem-calc(14);
      line-height: rem-calc(18);
      margin-bottom: rem-calc(20);
      margin-top: 0;

      @include media-breakpoint-up(md) {
        font-size: rem-calc(20);
        line-height: 1.1;
        margin-bottom: rem-calc(12);
      }
    }

    .attribute__label {
      align-items: center;
      font-size: rem-calc(19);
      font-weight: $font-weight-semibold;
      line-height: rem-calc(25);

      .value {
        font-size: rem-calc(16);
        line-height: rem-calc(21);
        font-weight: $headings-font-weight;
      }
    }

    .attribute__header:has(.non-input-label.color) {
      padding-left: 0;

      .non-input-label.color {
        font-size: rem-calc(15);

        .value {
          font-size: rem-calc(13);
        }
      }
    }

    .tile-desc {
      font-family: $headings-font-family;
      font-size: rem-calc(14);
      line-height: rem-calc(20);
      margin-bottom: rem-calc(20);

      @include media-breakpoint-up(md) {
        font-size: rem-calc(16);
        line-height: rem-calc(20);
      }
    }

    .add-to-cart {
      font-family: $headings-font-family;
      font-size: rem-calc(16);
      line-height: rem-calc(21);
    }
  }

  .index-selector {
    position: absolute;
    top: 50%;
    right: rem-calc(35);

    .index-selector-item {
      background: $gray-500;
      height: rem-calc(55);
      margin-bottom: rem-calc(12);
      width: rem-calc(3);

      &:last-child {
        margin-bottom: 0;
      }

      &.active {
        background: $black;
      }
    }
  }
}
