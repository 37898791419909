@import "../../components/mixins";

.hero-slider.new-hero-slider .hero-slide {
  &__media-link {
    cursor: pointer;
  }

  img {
    position: relative;
    object-fit: cover;
    width: 100%;
    height: 100%;
    object-position: top;
  }

  &__title {
    line-height: 1;

    &.text-white {
      color: $white;
    }

    &.text-black {
      color: $black;
    }
  }

  &__copy {
    @include widget_body;

    &.text-white {
      color: $white;
    }

    &.text-black {
      color: $black;
    }
  }

  &__bar {
    position: absolute;
    bottom: 10%;
    left: 50%;
    transform: translate(-50%, -10%);

    @include media-breakpoint-up(lg) {
      left: 5%;
      right: 5%;
      transform: translate(0, 0);
    }

    height: auto;
    z-index: 1;

    .hero-slide__element-button {
      min-width: 230px;
    }

    .btn-link {
      text-transform: uppercase;
      font-weight: bold;
      // min-width: 230px;
      width: 100%;
      border: 2px solid $white;

      &--primary {
        color: $white;
        border-color: $white;
        background-color: rgba(0, 0, 0, 0);

        &.fullfilled {
          background-color: $black;
          border-color: $black;
        }
      }

      &--secondary {
        line-height: 1.6;
        color: $black;
        border-color: $black;
        text-decoration: none;

        &.fullfilled {
          background-color: $white;
          border-color: $white;
        }
      }
    }
  }
}
